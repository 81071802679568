<template>
	<div>
		<div id="">
			<el-image style="height: 400px; width: 100%" :src="require('@/assets/images/qk.jpg')"></el-image>
		</div>
		<div class="page-content">
			<span><i class="el-icon-s-grid"></i></span>

			<el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top: 3%" stretch="true">
				<!-- 	<el-tab-pane label="EI期刊" name="EI" icon="Apple">
					<div>
						<p><i class="el-icon-menu"></i> 按学科遴选期刊</p>
						<div class="eiSubject">
							<div class="">
								<p>
									EI学科一
								</p>
								<el-autocomplete class="inline-input" v-model="state1" :fetch-suggestions="querySearch1"
									style="width: 217px;" placeholder="请输入学科名称" @blur="handleSelect"></el-autocomplete>
							</div>
							<div class="">
								<p>
									EI学科二
								</p>
								<el-autocomplete class="inline-input" v-model="state2" :fetch-suggestions="querySearch2"
									style="width: 217px;" placeholder="请输入学科名称" @blur="handleSelect"></el-autocomplete>
							</div>
							<div class="">
								<p>
									EI学科三
								</p>
								<el-autocomplete class="inline-input" v-model="state3" :fetch-suggestions="querySearch3"
									style="width: 217px;" placeholder="请输入学科名称" @blur="handleSelect"></el-autocomplete>
							</div>
							<div class="">
								<p>
									EI学科四
								</p>
								<el-autocomplete class="inline-input" v-model="state4" :fetch-suggestions="querySearch4"
									style="width: 217px;" placeholder="请输入学科名称" @blur="handleSelect"></el-autocomplete>
							</div>
						</div>
					</div>
					<div>
						<p><i class="el-icon-menu"></i> 按其他标准遴选期刊</p>
						<div class="eiSubject" style="justify-content: left;">
							<div class="">
								<p>
									期刊名称
								</p>
								<el-input class="inline-input" v-model="eiJournalTitle" style="width: 217px;"
									placeholder="请输入期刊名称" @blur="handleSelect"></el-input>
							</div>
							<div class="" style="margin-left: 248px;">
								<p>
									国家
								</p>
								<el-input class="inline-input" v-model="eiCotegory" style="width: 217px;"
									placeholder="请输入国家" @blur="handleSelect"></el-input>
							</div>
						</div>
					</div>
				</el-tab-pane> -->
				<el-tab-pane label="SCI期刊&SSCI期刊" name="SCI">
					<div>
						<p><i class="el-icon-menu"></i> 按其他标准遴选期刊</p>
						<div class="eiSubject" style="justify-content: left;">
							<div class="">
								<p>
									期刊名称
								</p>
								<el-input class="inline-input" v-model="sciSsciJournalTitle" style="width: 217px;"
									placeholder="请输入期刊名称" @blur="handleSelect"></el-input>
							</div>
							<div class="" style="margin-left: 100px;">
								<p>
									国家
								</p>
								<el-input class="inline-input" v-model="sciSsciCotegory" style="width: 217px;"
									placeholder="请输入国家" @blur="handleSelect"></el-input>
							</div>
							<div class="" style="margin-left: 100px;">
								<p>
									分区
								</p>
								<el-select v-model="subregionKey" filterable placeholder="所有" @change="eiSubjectChange">
									<el-option v-for="item2 in subregionKeyValue" :key="item2.value" :label="item2.key"
										:value="item2.value">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<!-- SSCI期刊 -->
				<!-- 	<el-tab-pane label="SSCI期刊" name="SSCI">
				<div>
						<p><i class="el-icon-menu"></i> 按其他标准遴选期刊</p>
						<div class="eiSubject">
							<div class="">
								<p>
									期刊名称
								</p>
								<el-input class="inline-input" v-model="sciSsciJournalTitle" style="width: 217px;"
									placeholder="请输入期刊名称" @blur="handleSelect"></el-input>
							</div>
							<div class="">
								<p>
									国家
								</p>
								<el-input class="inline-input" v-model="sciSsciCotegory" style="width: 217px;"
									placeholder="请输入国家" @blur="handleSelect"></el-input>
							</div>
							<div class="">
								<p>
									OA
								</p>
								<el-select v-model="isOaKey" filterable placeholder="所有" @change="eiSubjectChange">
									<el-option v-for="item2 in isOaKeyValue" :key="item2.value" :label="item2.key"
										:value="item2.value">
									</el-option>
								</el-select>
							</div>
							<div class="">
								<p>
									分区
								</p>
								<el-select v-model="subregionKey" filterable placeholder="所有" @change="eiSubjectChange">
									<el-option v-for="item2 in subregionKeyValue" :key="item2.value" :label="item2.key"
										:value="item2.value">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
				</el-tab-pane> -->
				<el-tab-pane label="AHCI期刊" name="AHCI">
					<div>
						<p><i class="el-icon-menu"></i> 按期刊名称遴选期刊</p>
						<div class="eiSubject" style="justify-content: left;">
							<div class="">
								<p>
									期刊名称
								</p>
								<el-input class="inline-input" v-model="ahciJournalTitle" style="width: 217px;"
									placeholder="请输入期刊名称" @blur="handleSelect"></el-input>
							</div>
							<div class="" style="margin-left: 100px;">
								<p>
									国家
								</p>
								<el-input class="inline-input" v-model="ahciCotegory" style="width: 217px;"
									placeholder="请输入国家" @blur="handleSelect"></el-input>
							</div>
							<div class="" style="margin-left: 100px;">
								<p>
									分区
								</p>
								<el-select v-model="subregionKey" filterable placeholder="所有" @change="eiSubjectChange">
									<el-option v-for="item2 in subregionKeyValue" :key="item2.value" :label="item2.key"
										:value="item2.value">
									</el-option>
								</el-select>
							</div>
						</div>


					</div>
				</el-tab-pane>
				<el-tab-pane label="ESCI期刊" name="ESCI">
					<div>
						<p><i class="el-icon-menu"></i> 按期刊名称遴选期刊</p>
						<div class="eiSubject" style="justify-content: left;">
							<div class="">
								<p>
									期刊名称
								</p>
								<el-input class="inline-input" v-model="esciJournalTitle" style="width: 217px;"
									placeholder="请输入期刊名称" @blur="handleSelect"></el-input>
							</div>
							<div class="" style="margin-left: 248px;">
								<p>
									国家
								</p>
								<el-input class="inline-input" v-model="esciJournalLanguages" style="width: 217px;"
									placeholder="请输入国家" @blur="handleSelect"></el-input>
							</div>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>

		<el-divider class="divider"></el-divider>
		<div class="page-content">
			<div class="eiSubject journalList" v-loading="loadingFlag">
				<div class="journal" v-for="(journal, journalIndex) in journalList" :key="journalIndex"
					@mouseover="mouseOver(journalIndex)" @mouseleave="mouseLeave(journalIndex)"
					@click="journalDetails(journalIndex)">
					<div class="journal5" :class="active == journalIndex ? 'journal3' : ''"
						style="width: 100%; height: 100%">
						<el-row :gutter="20">
							<el-col :span="8" style="padding: 20px 0 0 35px;">


								<div v-if="!journal.isNotImageAvailable && journal.indexFaceHref">
									<img style="width: 168px; height: 222px" :src="journal.indexFaceHref"
										@error="checkImageAvailabilityTwo(journal, journalIndex)" alt="Image" />
								</div>
								<div v-else class="fallback-bg">
									<div>
										<div style="height: 42px;max-width: 150px;padding: 10px 5px 0px;overflow: hidden;"
											class="three">
											{{ journal.journalName }}
										</div>
										<div style="height: 150px;max-width: 150px;padding:10px 5px 0px;margin-top:10px;color: #333333;font-style: oblique;font-weight: 600;"
											class="eight">
											{{ journal.publisherName }}
										</div>
									</div>
								</div>

							</el-col>
							<el-col :span="16">
								<p class="two title">{{ journal.journalTitle }}</p>
								<div style="line-height: 15px">
									<p style="font-weight: bold;">{{journal.journalName}}</p>
									<p>
										<span style="color: #767288">ISSN：</span>
										<span style="color: #858484">{{ journal.issn }}</span>
									</p>
									<p>
										<span style="color: #767288">E-ISSN：</span>
										<span style="color: #858484">{{ journal.eissn }}</span>
									</p>
									<p class="two" :title="journal.publisher" style="line-height: 20px;">
										<span style="color: #767288">出版商：</span><span
											style="color: #858484">{{ journal.publisherName }}</span>
									</p>
									<!-- <p>
										<span style="color: #767288">OA：</span><span
											style="color: #858484">{{ journal.isOa }}</span>
									</p> -->
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
				<div v-if="show" style="margin: auto;">
					<el-empty description="暂无数据"></el-empty>
				</div>
			</div>
			<div class="pagination">
				
				<el-pagination layout="prev, pager, next" @current-change="handleCurrentChange"
					:current-page="currentPage" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from "@/request/http";
	// import { resolve } from "core-js/library/fn/promise";
	export default {
		props: ["activeNameF", "headSearch"],
		data() {
			return {

				active: "false",
				show: false,
				restaurants1: [],
				restaurants2: [],
				restaurants3: [],
				restaurants4: [],
				restaurants5: [],
				state1: null,
				state2: null,
				state3: null,
				state4: null,
				eiJournalTitle: null,
				eiCotegory: null,
				ahciJournalTitle: null,
				esciJournalTitle: null,
				sciSsciJournalTitle: null,
				sciSsciCotegory: null,
				esciJournalLanguages: null,
				// 弃用OA
				// isOaKey: null,
				subregionKey: null,
				ahciCotegory: null,
				total: 0,
				currentPage: 1,
				rotation: [
					"http://compendex.ac.cn/localFile/indexbanner.jpg",
					"http://compendex.ac.cn/localFile/indexbanner.jpg",
				],
				isOaKeyValue: [{
						key: "所有",
						value: ""
					},
					{
						key: "是",
						value: "是"
					},
					{
						key: "否",
						value: "否"
					},
				],
				//弃用的OA
				subregionKeyValue: [{
						key: "所有",
						value: ""
					},
					{
						key: "Q1",
						value: "Q1"
					},
					{
						key: "Q2",
						value: "Q2"
					},
					{
						key: "Q3",
						value: "Q3"
					},
					{
						key: "Q4",
						value: "Q4"
					},
				],
				activeName: this.activeNameF,
				headSearch: this.headSearch,
				isImageAvailable: true,

				//期刊列表
				journalList: [],
				imageLoadFailed: false,
				journalType: "",
				//加载flag
				loadingFlag: false,
			};
		},
		methods: {

			//切换标签页
			handleClick() {
				this.currentPage = 1
				this.queryJournalList()
			},
			//切换分区
			eiSubjectChange() {
				this.currentPage = 1
				this.queryJournalList()
			},
			mouseOver(journalIndex) {
				this.active = journalIndex;
			},
			mouseLeave() {
				this.active = "false";
			},
			journalDetails(index) {
				this.journalList[index].activeName = this.activeName

				if (this.activeName == "SCI") {
					this.$router.push({
						name: "journalDetails",
						query: this.journalList[index]
					})
				} else if (this.activeName == "AHCI") {
					this.$router.push({
						name: "journalDetailsAHCI",
						query: this.journalList[index]
					})

				} else if (this.activeName == "ESCI") {
					this.$router.push({
						name: "journalDetailsESCI",
						query: this.journalList[index]

					})
				}
			},
			//更改页码
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.queryJournalList();
			},
			queryJournalList() {
				if(!this.loadingFlag) this.loadingFlag = true
				else return
				
				let _this = this;

				let url = "journalList";
				if (_this.activeName == "EI") {
					url = "iamsetEIJournalList";
				} else if (_this.activeName == "SCI" || _this.activeName == "SSCI") {
					url = "iamsetSciSsciJournalsPage";
				} else if (_this.activeName == "AHCI") {
					url = "getAhciJournalPage";
				} else if (_this.activeName == "ESCI") {
					url = "getEsciJournalPage";
				}

				let paramMap = {
					page: this.currentPage,
					headSearch: this.headSearch,
					subjectOne: this.state1 || null,
					subjectTwo: this.state2 || null,
					subjectThree: this.state3 || null,
					subjectFour: this.state4 || null,
					esciJournalName: this.esciJournalTitle,
					esciCountry: this.esciJournalLanguages,
					esciQuartile: this.subregionKey,
					sciSsciJournalName: this.sciSsciJournalTitle,
					sciSsciCountry: this.sciSsciCotegory,
					sciSsciQuartile: this.subregionKey,
					isOaKey: this.isOaKey,
					ahciJournalName: this.ahciJournalTitle,
					ahciCountry: this.ahciCotegory,
					ahciQuartile: this.subregionKey,
					journalType: this.activeName
				};

				$http
					.post(url, paramMap)
					.then(res => {
						if (res.data.length === 0) {
							this.show = true;
						} else {
							this.show = false;
						}
						this.total = res.dataCount - 0;
						this.currentPage = res.pageNo
						this.journalList = res.data;
						this.$forceUpdate();
						
						this.loadingFlag = false
					})
					.catch(err => {
						console.log(err);
					});
			},


			journalSubjectList() {
				let _this = this
				$http
					.post("EiJournalSubjectList")
					.then(function(res) {
						for (let i = 0; i < res.data.subjectOneList.length; i++) {
							let paramMap = {
								value: res.data.subjectOneList[i],
							};
							_this.restaurants1.push(paramMap)
						}

						for (let i = 0; i < res.data.subjectTwoList.length; i++) {
							let paramMap = {
								value: res.data.subjectTwoList[i],
							};
							_this.restaurants2.push(paramMap)
						}

						for (let i = 0; i < res.data.subjectThreeList.length; i++) {
							let paramMap = {
								value: res.data.subjectThreeList[i],
							};
							_this.restaurants3.push(paramMap)
						}

						for (let i = 0; i < res.data.subjectFourList.length; i++) {
							let paramMap = {
								value: res.data.subjectFourList[i],
							};
							_this.restaurants4.push(paramMap)
						}
					})
					.catch(function() {});
			},
			querySearch1(queryString, cb) {
				var restaurants = this.restaurants1;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			querySearch2(queryString, cb) {
				var restaurants = this.restaurants2;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			querySearch3(queryString, cb) {
				var restaurants = this.restaurants3;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			querySearch4(queryString, cb) {
				var restaurants = this.restaurants4;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},

			createFilter(queryString) {
				return (restaurant) => {
					return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			//输入框失去焦点查询
			handleSelect() {
				// let _this = this
				// this.currentPage = 1
				// setTimeout(function() {
				// 	_this.queryJournalList();
				// }, 500);

			},
			//回车和空格键查询
			handkeyCode(e) {
				//|| e.keyCode === 32
				this.currentPage = 1
				if (e.keyCode === 13) {
					console.log("回车确定")
					this.queryJournalList();
				}
			},
			//检查推荐期刊图片是否加载成功
			checkImageAvailabilityTwo(item, index) {
				item.isNotImageAvailable = true
				this.journalList[index] = item
			},

		},
		created() {

			window.addEventListener('keydown', this.handkeyCode, true) //开启监听键盘按下事件

		},
		mounted() {
			this.journalSubjectList();
			this.queryJournalList();
		},
		watch: {
			headSearch: function(headSearch) {
				// 更改
				// console.log("更改之确定")
				this.headSearch = headSearch
				this.queryJournalList();
			}
		},
		//是否引入头部和底部
		// created: function () {
		//   this.$emit("header", false);
		// },
		// created: function () {
		//   this.$emit("Footer", false);
		// },

		// 1. 根据条件 直接跳转到不同的路由
		// 2. 详情页   根据条件选择性显示
		// 3. 组件,根据条件选择性使用组件

	};
</script>
<style lang="scss" scoped>
	.divider {
		height: 2px;
		background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%),
			linear-gradient(#1b5faa, #1b5faa);
		background-blend-mode: normal, normal;
	}

	.eiSubject {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.eiSubject1:not(:nth-child(4n)) {
		margin-right: calc(46% / 2);
	}

	.journal {
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		width: 730px;
		height: 250px;
		// margin-right: 5%;
		margin-bottom: 2%;
	}

	.pagination {
		margin: 0 auto;
		text-align: center;
		margin-top: 2%;
	}

	.journal1 {
		padding: 14px 0px 0px 10px;
		float: left;
		width: 30%;
	}

	.journal2 {
		float: right;
		width: 68%;
	}

	.journal3 {
		border: solid 2px #1b5faa;
	}

	.one {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}

	.two {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}

	.title {
		// height: 60px;
		color: #333333;
		font-size: 20px;
	}
</style>
<style lang="scss">
	.el-carousel__item h3 {
		opacity: 0.75;
		height: 100px;
		margin: 0;
	}

	.el-tabs__item {
		font-size: 18px !important;
	}

	.el-icon-s-grid {
		position: absolute;
		padding-top: 5px;
		font-size: 30px;
		color: #1b5faa;
	}

	// .el-loading-mask {
	// 	background-color: transparent;
	// 	display: none;
	// }

	// .el-loading-spinner .circular {
	// 	display: none;
	// }

	.fallback-bg {
		height: 222px;
		width: 168px;
		background-image: url('../../assets/images/noPhoto.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}
</style>